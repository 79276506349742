import React from 'react'
import Navigation from '../components/navigation'
import Sketch from '../../static/sketch.min.js'
import styles from './projects.module.css'
import Helmet from 'react-helmet'
import Gallery from 'react-grid-gallery';
import MobileNavigation from '../components/mobile-menu'
import { Link } from 'gatsby'

/* eslint-disable */
class RootIndex extends React.Component {
  init() {
    /* Copyright (C) 2013 Justin Windle, http://soulwire.co.uk */
    var COLOURS = ['#e5989b', '#b5838d', '#6d6875', '#a0c4ff', '#bdb2ff'];
    var id = Math.floor(Math.random() * COLOURS.length)
    var radius = 0;

    const sk = Sketch.create({
      container: document.getElementById('container'),
      autoclear: false,
      eventTarget: document.getElementById('scrollAnchor2'),
      retina: 'auto',

      setup: function () {
        console.log('setup');
      },

      update: function () {
        radius = 2 + abs(sin(this.millis * 0.003) * 50);
      },

      // Event handlers

      keydown: function () {
        if (this.keys.C) this.clear();
      },

      // Mouse & touch events are merged, so handling touch events by default
      // and powering sketches using the touches array is recommended for easy
      // scalability. If you only need to handle the mouse / desktop browsers,
      // use the 0th touch element and you get wider device support for free.
      touchmove: function () {
        for (var i = this.touches.length - 1, touch; i >= 0; i--) {

          touch = this.touches[i];

          this.lineCap = 'round';
          this.lineJoin = 'round';
          this.fillStyle = this.strokeStyle = COLOURS[id % COLOURS.length];
          this.lineWidth = radius;

          this.beginPath();
          this.moveTo(touch.ox, touch.oy);
          this.lineTo(touch.x, touch.y);
          this.stroke();
        }
      }
    });
  }

  componentDidMount() {
    if(typeof window !== `undefined`) {
      /* eslint-disable */
      window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
      window['_fs_org'] = 'X6AZX';
      window['_fs_namespace'] = 'FS';
      (function(m,n,e,t,l,o,g,y){
          if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a,b){g("log",[a,b])};
          g.consent=function(a){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.2.0";
      })(window,document,window['_fs_namespace'],'script','user');
      /* eslint-enable */

      this.init();
    }
  }

  render() {
    return (
      <div id="scrollAnchor" style={{ position: 'absolute', overflow: 'auto', top: 0, bottom: 0, left: 0, right: 0 }}>
      <Helmet title={'Yann Stepienik'}>
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>
      <MobileNavigation />
        <div id="scrollAnchor2" style={{ minHeight: '100%', backgroundColor: 'transparent' }}>
          <div className={styles.container} id="container"></div>

          <div>
            <Navigation />
            <div className={styles.content}>
              <div className={styles.quote}>
                “Many painters are afraid of the blank canvas, but the blank canvas is afraid of the painter who dares and who has broken the spell of 'you can't' once and for all”
              <br />
              ― Vincent Van Gogh
              </div>
              <div className={styles.projects}>
                <br />
                <h1>Projects</h1>
                <div className={styles.projectsHolder}>
                  <h2>Comsos, GuPM, and other projects</h2>
                  <div>
                    Beyond the projects mentioned in my CV,
                    I had a few of my own personal projects scattered here and there.
                    Most of those projects are dear to me as they are the horses I rode to bring me to where I am today. As part of my job, I worked on both
                    small and gigantic codebases such as the one of the DailyMail. I also worked on a lot of different projects not directly related to web,
                    such as security gates for enterprise grade SSO, and finally, my own collection of various packages that can be found on Github.
                    <div className={styles.gitprojectholder}>
                      <div className={styles.gitproject}><Link target='_blank' to='https://github.com/azukaar/cosmos-server'>☁️<br /><strong>Cosmos</strong> <br /> Complete self-hosting platform to manage your server and expose a secure reverse-proxy</Link></div>
                      <div className={styles.gitproject}><Link target='_blank' to='https://github.com/azukaar/gupm'>🐶📦<br /><strong>GuPM</strong> <br /> Package manager, cli tool, scripts for all your projects and your system</Link></div>
                      <div className={styles.gitproject}><Link target='_blank' to='https://github.com/azukaar/electron-css'>🔬 <br /><strong>Electron-CSS</strong> <br /> Clean and flexible framework agnostic Style in JS, without any of the fuss of CSS and JSX</Link></div>
                      <div className={styles.gitproject}><Link target='_blank' to='https://github.com/azukaar/memory-efficient-object'>⚙️ <br /><strong>Memory Efficient Objects</strong> <br /> Bits and bytes mainpulation (int, char, uint, ...) in JS, using schema for serialisation</Link></div>
                      <div className={styles.gitproject}><Link target='_blank' to='https://github.com/azukaar/blossom-js'>🌸 <br /><strong>Blossom JS</strong> <br /> The web framework that will love you as much as you love it. Zero binding and 100% DOM powered</Link></div>
                      <div className={styles.gitproject}><Link target='_blank' to='https://github.com/azukaar'>➕ <br /><strong>More!</strong> <br /> Check my github for more projects</Link></div>
                    </div>
                  </div>
                  <h2>Cosmos Cloud</h2>
                  <div>
                    Perhaps my most interesting project to date, Cosmos is a self-hosting platform that allows you to manage your server and expose a secure reverse-proxy. It has a lot of features allowing end to end management of a server.
                    You can learn more about it by visiting the <a href="https://cosmos-cloud.io">official website</a> or on the <a href="https://github.com/azukaar/cosmos-server">Github repository</a>.
                  </div>
                  <br />
                  <br />
                  <center>
                  <img src="https://cosmos-cloud.io/hero.png" height="500px"/>
                  </center>
                  <div className={styles.galleryHolder}>
                    <Gallery images={[
                      {
                        src: "https://cosmos-cloud.io/screenshots/servapps.png",
                        thumbnail: "https://cosmos-cloud.io/screenshots/servapps.png",
                        thumbnailWidth: '198',
                        thumbnailHeight: '108',
                        caption: "Control, update, and monitor your containers directly from Cosmos. Expose those container with the internal reverse proxy directly in one UI!"
                      },
                      {
                        src: "https://cosmos-cloud.io/screenshots/market_2.png",
                        thumbnail: "https://cosmos-cloud.io/screenshots/market_2.png",
                        thumbnailWidth: '198',
                        thumbnailHeight: '108',
                        caption: "Browse and install apps that integrate with your setup. It will even setup security, databases and HTTPS rules for you."
                      },
                      {
                        src: "https://cosmos-cloud.io/screenshots/urls.png",
                        thumbnail: "https://cosmos-cloud.io/screenshots/urls.png",
                        thumbnailWidth: '198',
                        thumbnailHeight: '108',
                        caption: "Integrated reverse proxy, easy to setup and flexible, all from Cosmos' modern UI."
                      },
                    ]} />
                    <div className={styles.break}></div>
                    <div>

                    </div>
                  </div>
                  <h2>Mobiles apps</h2>
                  <div>
                    Most people will agree that Mobile applications define the future of how users will interact with content on the internet.
                    Over the past 10 years the volume of data transmitted to mobile phones has exploded, even if you only consider the audience shift
                    from desktop user. For this reason, I have always cultivated a fascination for mobile technology, with experience from very low level
                    Android development (In Tokyo I had to use the Java Native Interface to compile C code AI algorithms for maximum performance) to high-level
                    React-Native, Flutter, and Phonegap-like technologies.
                  </div>
                  <div className={styles.galleryHolder}>
                    <Gallery images={[
                      {
                        src: "/yoker1.png",
                        thumbnail: "/yoker1.png",
                        thumbnailWidth: '200',
                        thumbnailHeight: '400',
                        caption: "Yoker social connecting application in React Native and Node.JS [1/3]"
                      },
                      {
                        src: "/yoker2.png",
                        thumbnail: "/yoker2.png",
                        thumbnailWidth: '200',
                        thumbnailHeight: '400',
                        caption: "Yoker social connecting application in React Native and Node.JS [2/3]"
                      },
                      {
                        src: "/yoker3.png",
                        thumbnail: "/yoker3.png",
                        thumbnailWidth: '300',
                        thumbnailHeight: '200',
                        caption: "Yoker social connecting application in React Native and Node.JS [3/3]"
                      },
                      {
                        src: "/mocham2.jpg",
                        thumbnail: "/mocham2.jpg",
                        thumbnailWidth: '230',
                        thumbnailHeight: '400',
                        caption: "Mocham, challenge collaborative platform, written in React and NodeJS with GraphQL [1/3]"
                      },
                      {
                        src: "/mocham2.png",
                        thumbnail: "/mocham2.png",
                        thumbnailWidth: '200',
                        thumbnailHeight: '400',
                        caption: "Mocham, challenge collaborative platform, written in React and NodeJS with GraphQL [2/3]"
                      },
                      {
                        src: "/mocham4.png",
                        thumbnail: "/mocham4.png",
                        thumbnailWidth: '380',
                        thumbnailHeight: '200',
                        caption: "Mocham, challenge collaborative platform, written in React and NodeJS with GraphQL [1/3]"
                      },
                      {
                        src: "/hand0.png",
                        thumbnail: "/hand0.png",
                        thumbnailWidth: '308',
                        thumbnailHeight: '200',
                        caption: "Native AI to discriminate fake (photos) to real hands. Here a real hand is shown"
                      },
                      {
                        src: "/hand01.png",
                        thumbnail: "/hand01.png",
                        thumbnailWidth: '308',
                        thumbnailHeight: '200',
                        caption: "Native AI to discriminate fake (photos) to real hands. Here a photo of a hand is shown using another tablet"
                      },
                      {
                        src: "/hand1.png",
                        thumbnail: "/hand1.png",
                        thumbnailWidth: '2000',
                        thumbnailHeight: '1080',
                        caption: "Native AI to discriminate fake (photos) to real hands. Here a photo of a hand is shown using another tablet"
                      },
                    ]} />
                    <div className={styles.break}></div>
                    <div>

                    </div>
                  </div>
                  <h2>Game projects</h2>
                  <div>
                    Game development has always been a hobby of mine. What I find most interesting about it is the far higher complexity you find there
                    than in pretty much anywhere else. The thrill of spending a whole day optimizing mathematics and small chunks of code simply to
                    save a few milliseconds is the kind of puzzles that really stimulate the "developer creativity" that tends to be lacking especially among web and mobile developers.<br />
                    One of my favourite project to work on is Crystal Venture (<a href="https://crystal-venture.com">see here</a>), a voxel based game with sandbox mechanism. The non-traditional structure of the game, 
                    and the way you can empower the user to use their own creativity to create a unique set of challenge.
                    I wrote multiple versions of this game, the first one in C++ using only DirectX (pure system development) then I re-created in Unity (C#), to finally move to Unreal-Engine (C++).
                  </div>
                  <div className={styles.galleryHolder}>
                    <Gallery images={[
                      {
                        src: "/c1.png",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/c1.png",
                        caption: "Crystal Venture (first Unity version), a cube based adventure game"
                      },
                      {
                        src: "/c3.png",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/c3.png",
                        caption: "Crystal Venture (first Unity version), a cube based adventure game"
                      },
                      {
                        src: "/c8.png",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/c8.png",
                        caption: "Crystal Venture (first Unity version), a cube based adventure game"
                      },
                      {
                        src: "/c5.png",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/c5.png",
                        caption: "Crystal Venture (Remade in Unreal Engine), a cube based adventure game"
                      },
                      {
                        src: "/c6.png",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/c6.png",
                        caption: "Crystal Venture (Remade in Unreal Engine), a cube based adventure game"
                      },
                      {
                        src: "/c7.png",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/c7.png",
                        caption: "Crystal Venture (Remade in Unreal Engine), a cube based adventure game"
                      },
                    ]} />
                  </div>
                  <div className={styles.break}></div>
                  <div>
                    While I was working in Japan I had to work on a 3D demo for vision recognition technologies. That's how I designed and developed Tori no Mori.
                    A game whose base mechanism is to control the main character using your hand (a bit like a Kinect would do). You fly around moving your hand, and 
                    shoot by forming a fist with your hand.
                    The game has been made from scratch using Ogre and C++. I had to develop my own scripting engine (parsing XML files) to create cinematics and levels.
                  </div>
                  <div className={styles.galleryHolder}>
                    <Gallery images={[
                      {
                        src: "/tnm1.jpg",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/tnm1.jpg",
                        caption: "Tori no Mori, a 3D demo for hand recognition."
                      },
                      {
                        src: "/tnm3.jpg",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/tnm3.jpg",
                        caption: "All the models have been and rigged in Blender"
                      },
                      {
                        src: "/tnm8.jpg",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/tnm8.jpg",
                        caption: "HMI are fully integrated to the  gameplay and story."
                      },
                      {
                        src: "/tnm5.jpg",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/tnm5.jpg",
                        caption: "Tori no Mori, a 3D demo for hand recognition."
                      },
                      {
                        src: "/tnm6.jpg",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/tnm6.jpg",
                        caption: "Tori no Mori, a 3D demo for hand recognition."
                      },
                      {
                        src: "/tnm7.jpg",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/tnm7.jpg",
                        caption: "Tori no Mori, a 3D demo for hand recognition."
                      },
                      {
                        src: "/tnm9.jpg",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/tnm9.jpg",
                        caption: "Tori no Mori, a 3D demo for hand recognition."
                      },
                      {
                        src: "/tnm11.jpg",
                        thumbnailWidth: '1920',
                        thumbnailHeight: '1080',
                        thumbnail: "/tnm11.jpg",
                        caption: "Tori no Mori, a 3D demo for hand recognition."
                      },
                      {
                        src: "/tnm12.jpg",
                        thumbnailWidth: 520,
                        thumbnailHeight: 320,
                        thumbnail: "/tnm12.jpg",
                        caption: "Tori no Mori, a 3D demo for hand recognition."
                      },
                    ]} />
                  </div>
                  <div className={styles.break}></div>
                  {/* <div>
                    Another one that I really enjoyed working on. this is a small game I had to develop using only the base canvas element in JS. It has been a very interesting project to explore the base
                    mechanism of JS and the browser.
                  </div>
                  <div className={styles.galleryHolder}>
                    <Gallery images={[
                      {
                        src: "/SM1.jpg",
                        thumbnailWidth: '1600',
                        thumbnailHeight: '1080',
                        thumbnail: "/SM1.jpg",
                        caption: "RPG engine written in JS"
                      },
                      {
                        src: "/SM2.jpg",
                        thumbnailWidth: '1600',
                        thumbnailHeight: '1080',
                        thumbnail: "/SM2.jpg",
                        caption: "RPG engine written in JS"
                      },
                      {
                        src: "/SM3.jpg",
                        thumbnailWidth: '1900',
                        thumbnailHeight: '1650',
                        thumbnail: "/SM3.jpg",
                        caption: "RPG engine written in JS"
                      },
                      {
                        src: "/SM3D.jpg",
                        thumbnailWidth: '1900',
                        thumbnailHeight: '1500',
                        thumbnail: "/SM3D.jpg",
                        caption: "RPG engine written in JS, this is the 3D \"remaster\""
                      },
                    ]} />
                  </div>
                  <div className={styles.break}></div> */}
                  <h2>Other projects</h2>
                  <div>Over time I did a lot of different things, including completely unrelated things that I love to talk about such as my book!</div>
                  <div className={styles.galleryHolder}>
                    <Gallery images={[
                      // {
                      //   src: "/hand3.png",
                      //   thumbnail: "/hand3.png",
                      //   thumbnailWidth: '2000',
                      //   thumbnailHeight: '900',
                      //   caption: "Hand reading AI algorithm developed for my contract in Tokyo"
                      // },
                      // {
                      //   src: "/hand4.png",
                      //   thumbnail: "/hand4.png",
                      //   thumbnailWidth: '2000',
                      //   thumbnailHeight: '900',
                      //   caption: "Hand reading AI algorithm developed for my contract in Tokyo"
                      // },
                      // {
                      //   src: "/JSC1.jpg",
                      //   thumbnail: "/JSC1.jpg",
                      //   thumbnailWidth: '1000',
                      //   thumbnailHeight: '1000',
                      //   caption: "C-to-JS compiler. Unfortunately I have never been able to compile the Lib-C with it!"
                      // },
                      {
                        src: "/book.png",
                        thumbnailWidth: '1450',
                        thumbnailHeight: '2000',
                        thumbnail: "/book.png",
                        caption: "The cover I drawn for my book! Ask me about it!"
                      },
                      {
                        src: "/manga.jpg",
                        thumbnailWidth: '1500',
                        thumbnailHeight: '2000',
                        thumbnail: "/manga.jpg",
                        caption: "I also love drawing"
                      },
                      // {
                      //   src: "/cvlogo.webp",
                      //   thumbnailWidth: '1920',
                      //   thumbnailHeight: '1080',
                      //   thumbnail: "/cvlogo.webp",
                      //   caption: "Another cover I have drawn"
                      // },
                      // {
                      //   src: "/RT2.png",
                      //   thumbnailWidth: '1500',
                      //   thumbnailHeight: '1080',
                      //   thumbnail: "/RT2.png",
                      //   caption: "Ray Tracer Engine written in C and SDL"
                      // },
                      {
                        src: "/Untitled-1 copy.png",
                        thumbnailWidth: '1500',
                        thumbnailHeight: '1080',
                        thumbnail: "/Untitled-1 copy.png",
                        caption: "Another old drawing"
                      },
                      {
                        src: "/jap.png",
                        thumbnailWidth: '1500',
                        thumbnailHeight: '1080',
                        thumbnail: "/jap.png",
                        caption: "Paper published with the University Of Engineering of Tokyo"
                      },
                    ]} />
                  </div>
                  <div className={styles.break}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RootIndex
